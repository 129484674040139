import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import DocumentationLayout, {
  Header,
  TabNav,
} from '../components/documentation/layout'
import SEO from '../components/seo'
import Elements from '../components/documentation/elements'

const Activity = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={`${data.contentfulActivity.identifier} ${
          data.contentfulActivity.name
        } | Community Rating System (CRS)`}
        description={
          data.contentfulActivity.intro
            ? data.contentfulActivity.intro.intro
            : ''
        }
        keywords={['community rating system', 'CRS guide', 'CRS documentation']}
      />
      <DocumentationLayout
        data={data.allContentfulActivityOrder.nodes}
        currentItem={`${data.contentfulActivity.identifier} ${
          data.contentfulActivity.name
        }`}
      >
        <Header
          identifier={data.contentfulActivity.identifier}
          name={data.contentfulActivity.name}
          intro={
            data.contentfulActivity.intro
              ? data.contentfulActivity.intro.intro
              : null
          }
          nav={
            <TabNav
              slug={data.contentfulActivity.slug}
              documentsCount={
                data.contentfulActivity.documents
                  ? data.contentfulActivity.documents.length
                  : 0
              }
              linksCount={
                data.contentfulActivity.links
                  ? data.contentfulActivity.links.length
                  : 0
              }
              relatedCount={
                data.contentfulActivity.related
                  ? data.contentfulActivity.related.length
                  : 0
              }
            />
          }
        />
        <Elements data={data.contentfulActivity.elements} />
      </DocumentationLayout>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulActivity(id: { eq: $id }) {
      name
      identifier
      slug
      intro {
        intro
      }
      documents {
        id
      }
      links {
        id
      }
      related {
        id
      }
      elements {
        id
        identifier
        name
        effort
        requirement
        intro {
          intro
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }

    allContentfulActivityOrder(sort: { fields: identifier }) {
      nodes {
        id
        identifier
        name
        slug
        activities {
          id
          identifier
          name
          slug
          elements {
            id
            identifier
            name
          }
        }
      }
    }
  }
`

export default Activity
