import React from 'react'
import cx from 'classnames'

import { Section, Container } from './layout'
import Markdown from './markdown'
import Disclaimer from './disclaimer'
import './__styles__/elements.module.scss'

const Elements = ({ data }) => (
  <Section>
    {data.map(element => (
      <div key={element.id} styleName="element-section" id={element.id}>
        <Container>
          <div styleName="header">
            <h3 styleName="title">
              <span styleName="identifier">{element.identifier}</span>
              <br />
              {element.name}
            </h3>
            {!!element.intro && <p styleName="intro">{element.intro.intro}</p>}

            <div styleName="info">
              {!!element.effort && (
                <span
                  styleName={cx('effort-level', {
                    low: element.effort === 'low',
                    moderate: element.effort === 'moderate',
                    high: element.effort === 'high',
                  })}
                >
                  Level of effort = <span>{element.effort}</span>
                </span>
              )}
              {!!element.requirement && (
                <span styleName="requirement">{element.requirement}</span>
              )}
            </div>
          </div>
        </Container>
        {!!element.content && <Markdown content={element.content} />}
      </div>
    ))}
    <Disclaimer />
  </Section>
)

export default Elements
